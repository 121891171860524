/* eslint-disable quotes */
export default {
  // Notification
  'notification.name': 'Notification',
  'notification.title': 'Notification list',
  'notification.table.title': 'Title',
  'notification.table.method': 'Method',
  'notification.table.sendTime': 'Send time',
  'notification.table.notificationRecipient': 'Recipients',
  'notification.table.status': 'Status',
  'notification.table.creator': 'Creator',
  'notification.table.action': 'Action',
  'notification.table.createdAt': 'Date created',
  'notification.table.status.id': '{status, select, 1 {Unsent} 2 {Sent} other {}}',
  'notification.confirm.title.delete': 'Want to remove notifications?',

  // Page
  'notification.add.name': 'Add New',
  'notification.edit.name': 'Update',
  'notification.add.title.name': 'First and last name',
  'notification.add.title.emailAddress': 'Email',
  'notification.add.title.message.rule': 'Please enter',
  'notification.add.title.add': 'Add new notification',
  'notification.add.title.update': 'Notification update',
  'notification.add.title.content': 'Content:',
  'notification-page.table.code': 'Member ID',
  'notification-page.table.name': 'Member name',
  'notification-page.table.emailAddress': 'Email',
  'notification-page.table.type': 'Member type',
  'notification-page.table.action': 'Action',
  'notification-page.form.title': 'Title',
  'notification-page.form.inputPlaceholder': 'Please enter title',
  'notification-page.form.message': 'Please enter form',
  'notification-page.form.method.error': "Can't send notification to the club",
  'notification-page.form.method': 'Method:',
  'notification-page.form.sendTime': 'Send time:',
  'notification-page.form.sendTime.rules.message': 'Please enter delivery time',
  'notification-page.form.immediately': 'Immediately',
  'notification-page.form.option': 'Options',
  'notification-page.form.receiver': 'Recipient:',
  'notification-page.form.receiver.title.1': 'Members of the guild',
  'notification-page.form.receiver.title.2': 'People outside the system',
  'notification-page.form.addRecipients': 'Add recipient',
  'notification-page.form.program': 'Program',
  'notification-page.form.program.placeholder': 'Please enter program',

  // PageInfo
  'notification.info.name': 'Details',
  'notification.info.participants': 'Participants',
  'notification-page-info.content.title': 'Title',
  'notification-page-info.content.method': 'Method',
  'notification-page-info.content.sendTime': 'Send time',
  'notification-page-info.content.content': 'Content',
  'notification-page-info.table.code': 'Member ID',
  'notification-page-info.table.name': 'Member name',
  'notification-page-info.table.emailAddress': 'Email',
  'notification-page-info.table.type': 'Member type',
  'notification-page-info.table.market': 'Market',
  'notification-page-info.table.targetCustomer': 'Target customer',
  'notification-page-info.table.enterpriseScale': 'Enterprise Scale',
  'notification-page-info.table.confirm': 'Confirm',
  'notification-page-info.table.confirm.id':
    '{status, select, 1 {Unconfimred} 2 {Confirm} 3 {Inactive} other {}}',

  // Modal
  'modal-notification-page.table.title': 'Add recipient',
  'modal-notification-page.table.name': 'Member name',
  'modal-notification-page.table.code': 'Member ID',
  'modal-notification-page.table.emailAddress': 'Email',
  'modal-notification-page.table.type': 'Member type',
  'modal-notification-page.table.memberFields': 'Field',
  'modal-notification-page.table.memberMarkets': 'Market',
  'modal-notification-page.table.memberTargetCustomers': 'Target customer',
  'modal-notification-page.table.enterpriseScale': 'Enterprise Scale',
  'modal-notification-page.table.type.id':
    '{status, select, 1 {Unit member} 2 {Affiliate member} 3 {Business partner} 4 {Executive Committee} 5 {Club} 6 {Individual member} 7 {Personal partner} 99 {People outside the system} other {} }',
  'modal-notification-page.table.type.id.1': 'Unit member',
  'modal-notification-page.table.type.id.2': 'Affiliate member',
  'modal-notification-page.table.type.id.3': 'Business partner',
  'modal-notification-page.table.type.id.4': 'Executive Committee',
  'modal-notification-page.table.type.id.5': 'Club',
  'modal-notification-page.table.type.id.6': 'Individual member',
  'modal-notification-page.table.type.id.7': 'Personal partner',

  'modal-notification-page.table.enterpriseScale.id':
    '{status, select, 1 {50 - 100 people} 2 {100 - 200 people} 3 {200 - 500 people} 4 {500 people} other {--}}',

  // updade on 18/10/2023
  'notification.info': 'Information',
  'notification.receiver': 'Receiver',
  'notification.requiredReceiver': 'Enter receiver',
  'notification.importFile': 'Upload file:',
  'notification.email-invalid': 'Invalid mail ',
  'notification.email-already': 'Email is duplicated',
  'notification.email-required': 'Please enter email',
  'modal-notification-page.useLastMember': 'Use last receiver',
  'modal-notification-page.chooseMeeting': 'Chose meeting',
  'modal-notification-page.chooseNotification': 'Chose notification',
  'modal-notification-page.chooseEvent': 'Chose event',
  'modal-notification-page.fullName': 'Full name',
};
